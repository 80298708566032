<template>
  <v-dialog width="60%" v-model="openDialog">
    <v-card>
      <v-card-actions>
        <h3 class="font-weight-bold mt-6">
          {{ $t('Profile Config') }}
        </h3>
      </v-card-actions>
      <v-divider />
      <Survey :survey="surveyResponse" />
      <v-divider />
      <v-card-actions class="mt-2 mb-4 mx-1">
        <v-spacer></v-spacer>
        <v-btn color="#F15858" class="text-white" @click="saveResponse()">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { default as clientPocketBase } from '@/helpers/pocketbase'
import { Model, StylesManager } from 'survey-core'
import 'survey-core/defaultV2.min.css'
import { Survey } from 'survey-vue-ui'

StylesManager.applyTheme('defaultV2')

export default {
  name: 'ResponseSurvey',
  components: {
    Survey,
  },
  data() {
    return {
      openDialog: false,
      surveyResponse: '',
      contactsData: [],
      surveyJson: {
        elements: [],
      },
      contacts: [],
      title: 'add',
    }
  },
  async mounted() {},
  beforeCreate() {},
  beforeDestroy() {
    this.surveyResponse = null
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  methods: {
    async open() {
      const parents = this
      let foundForm = await clientPocketBase
        .collection('crm_contact_form')
        .getOne(this.$Md5Hash(`Profile-Config - ${this.user.sub_id}`))
        .then(async res => {
          try {
            let foundResponse = await clientPocketBase
              .collection('crm_form_response')
              .getOne(this.$Md5Hash(this.$route.params.id + '-' + this.user.sub_id), {
                form: this.$Md5Hash(`Profile-Config - ${this.user.sub_id}`),
                response_data: this.surveyResponse.data,
                subId: this.user.sub_id,
                contacts: this.$route.params.id,
              })
            this.surveyJson.elements = res.survey_json
            this.surveyResponse = new Model(this.surveyJson)
            this.surveyResponse.data = foundResponse.response_data
            this.surveyResponse.showNavigationButtons = false
            parents.openDialog = true
          } catch (error) {
            this.surveyJson.elements = res.survey_json
            this.surveyResponse = new Model(this.surveyJson)
            this.surveyResponse.showNavigationButtons = false
            parents.openDialog = true
          }
        })
        .catch(err => {
          if (err.status == 404) {
            this.$emit('validation-profile')
          }
          // console.log(err, 'ini sayang')
        })
    },
    async saveResponse() {
      const nanoId = this.$nanoid()
      try {
        await clientPocketBase
          .collection('crm_form_response')
          .update(this.$Md5Hash(this.$route.params.id + '-' + this.user.sub_id), {
            response_data: this.surveyResponse.data,
          })
        this.$emit('fetch-profile')
      } catch (error) {
        try {
          await clientPocketBase.collection('crm_form_response').create({
            id: this.$Md5Hash(this.$route.params.id + '-' + this.user.sub_id),
            form: this.$Md5Hash(`Profile-Config - ${this.user.sub_id}`),
            response_data: this.surveyResponse.data,
            subId: this.user.sub_id,
            contacts: this.$route.params.id,
            type: 'Profile',
          })
          this.$emit('fetch-profile')
        } catch (error) {
          console.log(error)
        }
      }
      this.openDialog = false
    },
  },
}
</script>
